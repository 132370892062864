import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Grid, createStyles, makeStyles, Container } from '@material-ui/core'
import Master from '../components/Master'

const Qualidade = () => {

    const data = useStaticQuery(graphql`
        query {
            page(id: {eq: "5efa2b85226aed4281876a70"}) {
                title
                description
                keywords
                details
            }
        }
    `)
    const page = data.page

    const useStyles = makeStyles(theme =>
        createStyles({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            center: {
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
            },
            content: {
                padding: theme.spacing(0, 3, 6),
            },
            imagePaper: {
                width: '100%',
                marginTop: '10px',
                borderRadius: '4px'
            },
        }),
    )

    const classes = useStyles()

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}/qualidade`}
                noindex={true}
                nofollow={true}
                openGraph={{
                    url: `${process.env.SITE_URL}/qualidade`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Container maxWidth="md" component="main" className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.center}>{page.title}</h1>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <img className={classes.imagePaper} src={`https://firebasestorage.googleapis.com/v0/b/indall.appspot.com/o/images%2Flayout%2Fqualidade.jpg?alt=media&token=65496437-d8e6-4fce-9f47-73f4dbbab7a5`} title={page.title} alt={page.title} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div dangerouslySetInnerHTML={{ __html: page.details }} />
                    </Grid>
                </Grid>
            </Container>
        </Master>
    )
}

export default Qualidade